<template>
    <el-scrollbar class="integration">
        <div class="integration-course">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="integration-breadcrumb">
                <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="courses-list">
                <div class="course-item" v-for="(item, index) in newsList" :key="index">
                    <div class="news-desc">
                        <div class="title">
                            <h4 @click="toNewsDetail(item)">{{item.title}}</h4>
                            <span class="time">{{item.create_time}}</span>
                        </div>
                        <p>
                            {{item.subtitle}}
                        </p>
                    </div>
                    <div class="news-cover" @click="toNewsDetail(item)">
                        <img :src="item.cover" alt="">
                    </div>
                </div>
            </div>
            <el-pagination class="pages-center" background
                           :current-page.sync="newsPages.currentPageNum"
                           :page-size="newsPages.eachPageNum"
                           :total="newsPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="newsCurrentChange">
            </el-pagination>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "NewsCenter",
        data() {
            return {
                //新闻列表
                newsList: [],
                //分页
                newsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getNewsList();
        },
        methods: {
            getNewsList() {
                let params = {
                    page: this.newsPages.currentPageNum,
                    limit: this.newsPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.newsList, params, (res) => {
                    if (res.code === 200) {
                        console.log('newslist', res)
                        this.newsList = res.data
                        this.newsPages.total = res.total
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            newsCurrentChange(val) {
                this.newsPages.currentPageNum = val;
                this.getNewsList();
            },
            toNewsDetail(item) {
                console.log(item)
                this.$router.push({path: '/qualityCourse/newsCenterDetail', query: {newsId: item.id}})
            },
        }
    }
</script>

<style scoped lang="scss">
    .integration {
        width: 100%;
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .courses-list {
        flex: 1;
        height: calc(100% - 79px);
        padding: 20px 0 0 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        * {
            box-sizing: border-box;
        }
        .course-item {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 220px;
            margin-bottom: 20px;
            border-bottom: 1px solid #EAEAEA;
            .news-cover {
                background: #fdf6ef;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 320px;
                height: 200px;
                margin-left: 20px;
                margin-bottom: 20px;
                cursor: pointer;
                border-radius: 8px;
                overflow: hidden;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .news-desc {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                height: 100%;
                .title {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-bottom: 20px;
                    h4 {
                        margin: 0 10px 0 0;
                        font-size: 16px;
                        color: #333333;
                        cursor: pointer;
                        &:hover {
                            color: #E21D1A;
                        }
                    }
                    .time {
                        color: #999999;
                    }
                }
                p {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 8;
                    overflow: hidden;
                    height: calc(100% - 63px);
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
    .integration-course {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        height: calc(100% - 20px);
        width: 1200px;
        margin: 0 auto;
        .integration-breadcrumb {
            border-bottom: 1px solid #EAEAEA;
            color: #666666;
            height: 30px;
        }
        .integration-course-list {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 20px 0;
                display: flex;
                justify-content: start;
                .banner {
                    width: 140px;
                    height: 198px;
                    margin-right: 20px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                span {
                    flex: 1;
                    width: calc(100% - 160px);
                }
            }
        }
    }
</style>